import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Fetch items action (with async API call)
export const fetchItems = createAsyncThunk('data/fetchItems', async () => {
  const response = await fetch('https://47tn5imz4c.execute-api.us-east-1.amazonaws.com/prod/fetch-cards', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ action: 'fetch' }), // Specify the 'fetch' action
  });

  if (!response.ok) {
    throw new Error('Failed to fetch items');
  }
  const data = await response.json();
  return JSON.parse(data.body); // Assuming the items are in 'body'
});

// Fetch categories action (with async API call)
export const fetchCategories = createAsyncThunk('data/fetchCategories', async () => {
    // Make the API request to the correct endpoint
    const response = await fetch('https://idvxu157q2.execute-api.us-east-1.amazonaws.com/prod/fetch-categories', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ action: 'fetch' }), // Specify the 'fetch' action
    });
  
    if (!response.ok) {
      throw new Error('Failed to fetch items');
    }
    const data = await response.json();
    return JSON.parse(data.body); // Assuming the items are in 'body'
});



const initialState = {
  categories: [],       // Store categories
  items: [],            // Store all fetched items
  filteredItems: [],    // Store filtered items based on selected category or search query
  selectedCategory: 'Anxiety',  // Default category
  searchQuery: '',      // Search query input
  loading: false,
  error: null,
};

const dataSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    setSelectedCategory: (state, action) => {
      state.selectedCategory = action.payload;
    },
    setSearchQuery: (state, action) => {
      state.searchQuery = action.payload;

    },
    setFilteredItems: (state, action) => {
      state.filteredItems = action.payload;
     },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCategories.pending, (state) => {
        state.loading = true;
        state.error = null;
        
      })
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.categories = action.payload;
        state.loading = false;
         
      })
      .addCase(fetchCategories.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
         
      })
      .addCase(fetchItems.pending, (state) => {
        state.loading = true;
        state.error = null;
       })
      .addCase(fetchItems.fulfilled, (state, action) => {
        state.items = action.payload;
        state.filteredItems = action.payload; // Set filtered items initially to all items
        state.loading = false;
       })
      .addCase(fetchItems.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
       });
  },
});

export const { setSelectedCategory, setSearchQuery, setFilteredItems } = dataSlice.actions;

export default dataSlice.reducer;
