import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { faSignOut, faSliders } from '@fortawesome/free-solid-svg-icons';
import "../App.css";

const Header = ({ selectedCategory, searchQuery, handleSearchChange, setIsopen, isopen }) => {
  const [query, setQuery] = useState(searchQuery);

  const handleChange = (e) => {
    setQuery(e.target.value);
    handleSearchChange(e); // Pass the change back to the parent component
  };

  const isOpen = () => {
    setIsopen(!isopen);
  }
  function logout() {
    // Remove the token from localStorage (or cookies)
    localStorage.removeItem('token'); // Or document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 UTC;'
    localStorage.removeItem('role')
    // Optionally, redirect user after logout
    window.location.href = '/login'; // Redirect to login page
  }
  return (
    <div className="header">
      <h1>{selectedCategory}</h1>
      <FontAwesomeIcon icon={faSliders} className='toggleicon' onClick={isOpen} />
      <li onClick={logout}  className="logout-header">
        <span className="icon" >
          <FontAwesomeIcon icon={faSignOut} className='st-icon-menu' />
        </span>
        <span style={{ marginLeft: "20px" }}>Log out</span>

      </li>
    </div>
  );
};

export default Header;
