import './Categories.css';
import React, { useEffect, useState } from 'react';

import axios from 'axios';
import API_BASE_URL from '../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faEdit, faPlus } from '@fortawesome/free-solid-svg-icons';
import 'reactjs-popup/dist/index.css';

const Categories = () => {

    const [popupAdd, setPopupAdd] = useState(false);
    const [popupEdit, setPopupEdit] = useState(false);
    const [categories, setCategories] = useState([]);
    const [itemdetail, setItemdetail] = useState();
    const [imagePreview, setImagePreview] = useState(); // State for image preview
    const [imageFile, setImageFile] = useState(null); // State for the actual image file

    const [title, setTitle] = useState('');
    const [categorie_id, setCategorie_id] = useState();

    const fetchCategories = async () => {
        try {
            const response = await fetch('https://idvxu157q2.execute-api.us-east-1.amazonaws.com/prod/fetch-categories', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ action: 'fetch' }), // Specify the 'fetch' action
            });

            if (!response.ok) {
                throw new Error('Failed to fetch items');
            }

            const data = await response.json(); // Await the JSON response
            setCategories(JSON.parse(data.body)); // Parse the body to set the users
            console.log(JSON.parse(data.body)); // Log the users
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
    };
    useEffect(() => {
        fetchCategories();
    }, []);

    const handlePopupAdd = () => {
        setPopupAdd(true);
        setTitle('');
        setImagePreview();

    }

    const handlePopupEdit = (ele) => {
        setPopupEdit(true);
        setItemdetail(ele);
        setImageFile(ele.imageUrl)
        setImagePreview(ele.imageUrl);
        setTitle(ele.title);
        setCategorie_id(ele.id);
    }

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setImageFile(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result); // Set the preview to the selected file
            };
            reader.readAsDataURL(file); // Read the file as Data URL (base64)
        }
    };

    const handleTitleChange = (e) => {
        setTitle(e.target.value); // Set the title from the textarea
    };


    const getContentTypeFromExtension = (filePath) => {
        const extension = filePath.split('.').pop().toLowerCase();

        // Map file extensions to Content-Type values
        switch (extension) {
            case 'jpg':
            case 'jpeg':
                return 'image/jpeg';
            case 'png':
                return 'image/png';
            case 'gif':
                return 'image/gif';
            case 'bmp':
                return 'image/bmp';
            case 'webp':
                return 'image/webp';
            default:
                return 'application/octet-stream';  // Default for unknown types
        }
    };

    const handleSubmitEdit = async (e) => {
        e.preventDefault();

        // Check if both title and imageFile are not empty
        if (!title || !imageFile) {
            console.error('Title and image are required.');
            return;
        }

        let imageData = null;

        // If the imageFile is a URL (i.e., fetched from the database), we just send the URL.
        if (typeof imageFile === 'string' && imageFile.startsWith('http')) {
            console.log(imageFile);

            // Extract the content type from the image extension
            const contentType = getContentTypeFromExtension(imageFile);

            imageData = {
                name: imageFile.split('/').pop(),
                content: imageFile,
                contentType: contentType
            };

            console.log(imageFile)

            try {
                const response = await fetch('https://idvxu157q2.execute-api.us-east-1.amazonaws.com/prod/fetch-categories', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        action: 'edit',
                        data: {
                            title: title,
                            id: categorie_id,
                            imageFile: imageData
                        }
                    }),
                });

                if (!response.ok) {
                    const errorText = await response.text();
                    console.error('Error response:', errorText);
                    throw new Error('Failed to add category');
                }

                const data = await response.json();
                console.log('Category added:', data);

                setTitle('');
                setImageFile(null);
                setImagePreview('');
                setPopupEdit(false);
                await fetchCategories();
            } catch (error) {
                console.error('Error adding category:', error.message());
            }
        } else {
            // Handle local image file selection (same logic as before)
            const reader = new FileReader();
            reader.onload = async () => {
                const base64Content = reader.result.split(',')[1];
                const contentType = imageFile.type;

                imageData = {
                    name: imageFile.name,
                    content: base64Content,
                    contentType: contentType
                };

                try {
                    const response = await fetch('https://idvxu157q2.execute-api.us-east-1.amazonaws.com/prod/fetch-categories', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            action: 'edit',
                            data: {
                                title: title,
                                id: categorie_id,
                                imageFile: imageData
                            }
                        }),
                    });

                    if (!response.ok) {
                        const errorText = await response.text();
                        console.error('Error response:', errorText);
                        throw new Error('Failed to add category');
                    }

                    const data = await response.json();
                    console.log('Category added:', data);

                    setTitle('');
                    setImageFile(null);
                    setImagePreview('');
                    setPopupEdit(false);
                    await fetchCategories();
                } catch (error) {
                    console.error('Error adding category:', error.message());
                }
            };

            reader.readAsDataURL(imageFile);
        }
    };










    const handleSubmitAdd = async (e) => {
        e.preventDefault();

        // Check if both title and imageFile are not empty
        if (!title || !imageFile) {
            console.error('Title and image are required.');
            return;
        }



        // Convert image file to base64
        const reader = new FileReader();
        reader.onload = async () => {
            const base64Content = reader.result.split(',')[1];  // Extract base64 without metadata
            const contentType = imageFile.type;  // 'image/jpeg', 'image/png', etc.

            console.log("Base64 Content:", base64Content);  // Debugging: Check the base64 content

            try {
                const response = await fetch('https://idvxu157q2.execute-api.us-east-1.amazonaws.com/prod/fetch-categories', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        action: 'store',
                        data: {
                            title: title,
                            imageFile: {
                                name: imageFile.name,  // Ensure only name is passed
                                content: base64Content,
                                contentType: contentType
                            }
                        }
                    }),
                });

                if (!response.ok) {
                    const errorText = await response.text();
                    console.error('Error response:', errorText); // Log response text for errors
                    throw new Error('Failed to add category');
                }

                const data = await response.json();
                console.log('Category added:', data);

                // Reset form
                setTitle('');
                setImageFile(null);
                setImagePreview('');

                // Close the popup
                setPopupAdd(false);

                // Refresh the categories list
                await fetchCategories();
            } catch (error) {
                console.error('Error adding category:', error);
            }
        };


        reader.readAsDataURL(imageFile); // Read the file as Data URL (base64)
    };





    const handleSubmitRemove = async (id) => {
        /* eslint-disable no-restricted-globals */
        if (confirm("Are you sure you want to delete that?")) {
            try {
                // Make the DELETE request
                const response = await fetch('https://idvxu157q2.execute-api.us-east-1.amazonaws.com/prod/fetch-categories', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        action: 'delete',
                        data: { id },
                    }),
                });

                if (!response.ok) {
                    throw new Error('Failed to delete the Categorie');
                }

                const data = await response.json();
                console.log('Categorie deleted:', data);

                await fetchCategories(); // Refresh the list
            } catch (error) {
                console.error('Error deleting Categorie:', error);
            }
        }
        /* eslint-enable no-restricted-globals */
    };
    return (
        <section className="ftco-section st_content">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-6 text-center mb-4">
                        <h2 className="heading-section">Categories</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 lists-container">
                              
                            <button className="button-21" role="button" onClick={() => handlePopupAdd()}>Add <FontAwesomeIcon icon={faPlus} className='' /></button>

                     

                        <div className="table-wrap">
                            <table className="table">
                                <thead className="thead-primary">
                                    <tr>

                                        <th>Image</th>
                                        <th>Title</th>
                                        <th>Actions</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        categories.length > 0 &&
                                        categories.map((ele) => {
                                            return (
                                                <tr className="alert" role="alert">
                                                    <td style={{ background: "#1A2B48" }}>
                                                        <img className='admin-categorie-img' src={ele.imageUrl} alt={ele.title} loading="lazy" />
                                                    </td>
                                                    <td>
                                                        <div className="email">
                                                            <span>{ele.title}</span>
                                                        </div>
                                                    </td>

                                                    <td className='btn-containere'>

                                                        <FontAwesomeIcon icon={faEdit} className='' onClick={() => { handlePopupEdit(ele) }} />
                                                        <FontAwesomeIcon icon={faClose} className='' onClick={() => handleSubmitRemove(ele.id)} />

                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            {
                popupAdd ? (
                    <div className="popup">
                        <div className="popup-inner">
                            <button className="close-btn" onClick={() => setPopupAdd(false)}>
                                &times;
                            </button>

                            <h2>Add new categorie</h2>
                            <form onSubmit={handleSubmitAdd}>
                                <div class="form-group">
                                    <label for="image">Image</label>
                                    <input type="file" name="image" id="image" onChange={handleImageChange} />
                                    <div style={{ background: "#1A2B48" }}>


                                        {imagePreview &&
                                            <img src={imagePreview} alt="Image Preview" width={50} onChange={handleImageChange} />
                                        }
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="title">Title</label>
                                    <textarea name="title" id="title" cols="30" rows="10" value={title}
                                        onChange={handleTitleChange}>
                                    </textarea>
                                </div>
                                <div class="form-action">
                                    <button type="submit">Add</button>
                                </div>
                            </form>


                        </div>
                    </div>
                ) : null
            }


            {
                popupEdit ? (
                    <div className="popup">
                        <div className="popup-inner">
                            <button className="close-btn" onClick={() => setPopupEdit(false)}>
                                &times;
                            </button>

                            {itemdetail && (
                                <>
                                    <h2>Edit category ({itemdetail.title})</h2>

                                    <form onSubmit={handleSubmitEdit}>
                                        <div className="form-group">
                                            <label htmlFor="image">Image</label>
                                            <input
                                                type="file"
                                                name="image"
                                                id="image"
                                                onChange={handleImageChange}
                                            />
                                            <div style={{ background: "#1A2B48", width: "20%" }}>


                                                {imagePreview &&
                                                    <img src={imagePreview} alt="Image Preview" width={50} onChange={handleImageChange} />
                                                }
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="title">Title</label>
                                            <textarea
                                                name="title"
                                                id="title"
                                                cols="30"
                                                rows="10"
                                                value={title}
                                                onChange={handleTitleChange}
                                            />
                                        </div>

                                        <div className="form-action">
                                            <button type="submit">Update</button>
                                        </div>
                                    </form>
                                </>
                            )}
                        </div>
                    </div>
                ) : null
            }
        </section>

    );
};

export default Categories;
