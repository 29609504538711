// store.js
import { configureStore } from '@reduxjs/toolkit';
import dataReducer from './reducers'; // Import the reducer from reducers.js

const store = configureStore({
  reducer: {
    data: dataReducer, // Ensure the state is correctly mapped here
  },
});

export default store;
