import './Categories.css';
import React, { useEffect, useState } from 'react';

import axios from 'axios';
import API_BASE_URL from '../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faEdit, faPlus } from '@fortawesome/free-solid-svg-icons';
import 'reactjs-popup/dist/index.css';

const Users = () => {

    const [popupAdd, setPopupAdd] = useState(false);
    const [popupEdit, setPopupEdit] = useState(false);
    const [login, setLogin] = useState();
    const [password, setPassword] = useState();
    const [itemdetail, setItemdetail] = useState();
    const [user_id, setUser_id] = useState();
    const [users, setUsers] = useState([])


    const fetchUsers = async () => {
        try {
            const response = await fetch('https://zf867osy4m.execute-api.us-east-1.amazonaws.com/prod/fetch-users', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ action: 'fetch' }), // Specify the 'fetch' action
            });

            if (!response.ok) {
                throw new Error('Failed to fetch items');
            }

            const data = await response.json(); // Await the JSON response
            setUsers(JSON.parse(data.body)); // Parse the body to set the users
            console.log(JSON.parse(data.body)); // Log the users
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };


    useEffect(() => {
        fetchUsers();
    }, []); // Empty dependency array to run only once



    const handlePopupAdd = () => {
        setPopupAdd(true);
        setLogin('');
        setPassword('');
    }

    const handleLoginChange = (e) => {
        setLogin(e.target.value); // Set the title from the textarea
    };
    const handlePasswordChange = (e) => {
        setPassword(e.target.value); // Set the title from the textarea
    };

    const handlePopupEdit = (ele) => {
        setPopupEdit(true);
        setItemdetail(ele);
        setLogin(ele.login);
        setPassword(ele.password);
        setUser_id(ele.id);
    }


    const handleSubmitEdit = async (e) => {
        e.preventDefault(); // Prevent form from reloading the page

        if (!login || !password) {
            alert("All fields are required!");
            return;
        }
        try {
            const response = await axios.post('https://solkllb70e.execute-api.us-east-1.amazonaws.com/prod/authentification', {
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    action: 'edit',
                    id:user_id,
                    login: login,
                    password: password,
                    newLogin: login,
                    newPassword: password
                }),
            });

            if (response.status !== 200) {
                const errorText = await response.data;
                console.error('Error response:', errorText);
                throw new Error('Failed to login');
            }

            const data = await JSON.parse(response.data.body);
            alert(data.message);

            // Close the popup
            setPopupEdit(false);

            // Refresh the user list
            await fetchUsers(); // Ensure fetchUsers is defined and updates the `users` state
        } catch (error) {
            console.error('Error adding user:', error);
        }
    };


    const handleSubmitAdd = async (e) => {
        e.preventDefault();

        if (!login || !password) {
            alert("All fields are required!");
            return;
        }

        try {
            const response = await axios.post('https://solkllb70e.execute-api.us-east-1.amazonaws.com/prod/authentification', {
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    action: 'register',
                    login: login,
                    password: password,
                    role: 'user'
                }),
            });

            if (response.status !== 200) {
                const errorText = await response.data;
                console.error('Error response:', errorText);
                throw new Error('Failed to login');
            }

            const data = await JSON.parse(response.data.body);
            console.log(data);

            if (data.message === 'User registered successfully.') {
                alert("Registration successful!");
                setPopupAdd(false);
            } else {
                alert(data.message || 'Registration failed.');
            }
            await fetchUsers(); // Ensure fetchUsers is defined and updates the `users` state

        } catch (error) {
            console.error('Error adding user:', error);
            alert(error.message || 'An unexpected error occurred.');
        }
    };










    const handleSubmitRemove = async (id) => {
        /* eslint-disable no-restricted-globals */
        if (confirm("Are you sure you want to delete that?")) {
            try {
                // Make the DELETE request
                const response = await fetch('https://zf867osy4m.execute-api.us-east-1.amazonaws.com/prod/fetch-users', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        action: 'delete',
                        data: { id },
                    }),
                });

                if (!response.ok) {
                    throw new Error('Failed to delete the user');
                }

                const data = await response.json();
                console.log('User deleted:', data);

                // Fetch the updated list of users
                const fetchUpdatedUsers = async () => {
                    const response = await fetch('https://zf867osy4m.execute-api.us-east-1.amazonaws.com/prod/fetch-users', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ action: 'fetch' }), // Fetch updated list
                    });

                    if (!response.ok) {
                        throw new Error('Failed to fetch updated users');
                    }

                    const updatedData = await response.json();
                    setUsers(JSON.parse(updatedData.body)); // Update the users list in state
                };

                await fetchUpdatedUsers(); // Refresh the list
            } catch (error) {
                console.error('Error deleting user:', error);
            }
        }
        /* eslint-enable no-restricted-globals */
    };

    return (
        <section className="ftco-section st_content">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-6 text-center mb-4">
                        <h2 className="heading-section">Users</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 lists-container">
                        <button className="button-21" role="button" onClick={() => handlePopupAdd()}>Add <FontAwesomeIcon icon={faPlus} className='' /></button>

                        <div className="table-wrap">
                            <table className="table">
                                <thead className="thead-primary">
                                    <tr>

                                        <th>Login</th>
                                        <th>Role</th>
                                        <th>Actions</th>

                                    </tr>
                                </thead>
                                <tbody>

                                    {
                                        users.map((ele) => {
                                            return (


                                                <tr className="alert" role="alert">

                                                    <td>
                                                        <div className="email">
                                                            <span>{ele.login}</span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="email">
                                                            <span>{ele.role}</span>
                                                        </div>
                                                    </td>
                                                    <td className='btn-containere'>

                                                        <FontAwesomeIcon icon={faEdit} className='' onClick={() => { handlePopupEdit(ele) }} />
                                                        {
                                                            ele.role != 'admin' &&

                                                            <FontAwesomeIcon icon={faClose} className='' onClick={() => handleSubmitRemove(ele.id)} />
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }


                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            {
                popupAdd ? (
                    <div className="popup">
                        <div className="popup-inner">
                            <button className="close-btn" onClick={() => setPopupAdd(false)}>
                                &times;
                            </button>

                            <h2>Add new user</h2>
                            <form onSubmit={handleSubmitAdd}>
                                <div class="form-group">
                                    <label for="login">Login</label>
                                    <input type="email" name="login" id="login" value={login} onChange={handleLoginChange} />
                                </div>
                                <div class="form-group">
                                    <label for="password">Password</label>
                                    <input type='password' name="password" id="password" value={password} onChange={handlePasswordChange} />
                                </div>
                                <div class="form-action">
                                    <button type="submit">Add</button>
                                </div>
                            </form>


                        </div>
                    </div>
                ) : null
            }


            {
                popupEdit ? (
                    <div className="popup">
                        <div className="popup-inner">
                            <button className="close-btn" onClick={() => setPopupEdit(false)}>
                                &times;
                            </button>

                            <h2>Edit user</h2>
                            <form onSubmit={handleSubmitEdit}>
                                <div class="form-group">
                                    <label for="login">Login</label>
                                    <input type="email" name="login" id="login" value={login} onChange={handleLoginChange} />
                                </div>
                                <div class="form-group">
                                    <label for="password">Password</label>
                                    <input type='password' name="password" id="password" value={password} onChange={handlePasswordChange} />
                                </div>
                                <div class="form-action">
                                    <button type="submit">Update</button>
                                </div>
                            </form>


                        </div>
                    </div>
                ) : null
            }
        </section>

    );
};

export default Users;
