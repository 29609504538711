// Dashboard.jsx
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Sidebarhome from './Sidebarhome';
import Categories from './Categories';
import Cards from './Cards';
import Users from './Users';

const AdminDashboard = () => {
  return (
    <div className="page-wrapper chiller-theme toggled">
      {/* Main content */}
      <main className="page-content">
        {/* Sidebarhome component */}
        <Sidebarhome />
        {/* Routes for changing main content */}
        <Routes>
          <Route path="/" element={<Categories />} />
          <Route path="/Cards" element={<Cards />} />

          <Route path="/Users" element={<Users />} />


          {/* Add more routes for other pages/components */}
        </Routes>
      </main>
    </div>
  );
};

export default AdminDashboard;
