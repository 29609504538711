import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import './Sidebarhome.css'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from 'axios';
import API_BASE_URL from "../config";
import $ from 'jquery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOut, faList } from '@fortawesome/free-solid-svg-icons';
const Sidebarhome = () => {
    const [countguider, setCountGuider] = useState(0);
    const [countRatings, setCountRatings] = useState(0);
    const [countreclamations, setCountreclamations] = useState(0);

    useEffect(() => {



        $("#close-sidebar").on('click', function () {
            $(".page-wrapper").removeClass("toggled");
            $('body').removeClass('with-panel-right-reveal');
            $(".with-panel-right-reveal .sidenav-overlay").hide();
        });

        $("#show-sidebar").on('click', function () {
            $('body').addClass('with-panel-right-reveal');
            $(".page-wrapper").addClass("toggled");
            $(".with-panel-right-reveal .sidenav-overlay").show();
        });
    }, []);

    useEffect(() => {

        const handleGetGuider = async (e) => {
            try {
                const res = await axios.get(`${API_BASE_URL}/show_guider_notverrified`);
                setCountGuider(res.data.length);

                const response = await axios.get(`${API_BASE_URL}/list_of_ratings`);
                setCountRatings(response.data.filter(ele => { return ele.status == 0 }).length);

                const resReclamation = await axios.get(`${API_BASE_URL}/AllReclamations`);
                setCountreclamations(resReclamation.data.filter((ele) => { return ele.reclamation.solved == 0 }).length);
            } catch (error) {
                console.error(error);
            }
        };
        handleGetGuider();
    }, []);



    const [activeItem, setActiveItem] = useState(null);

    const handleItemClick = (itemName) => {
        setActiveItem(itemName);
    };

    function logout() {
        // Remove the token from localStorage (or cookies)
        localStorage.removeItem('token'); // Or document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 UTC;'

        fetch('https://solkllb70e.execute-api.us-east-1.amazonaws.com/prod/authentification', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({ action: 'logout' })
        })
            .then(response => response.json())
            .then(data => {
                // Handle server response if necessary
                console.log(data.message);
            })
            .catch(error => {
                console.error('Error during logout:', error);
            });

        // Optionally, redirect user after logout
        window.location.href = '/login'; // Redirect to login page
    }

    return (
        <>

            <nav id="sidebar" class="sidebar-wrapper">
                <a id="show-sidebar" class="btn btn-sm btn-dark" href="#">
                    <i class="fa fa-bars"></i>
                </a>
                <div class="sidebar-content">
                    <div class="sidebar-header">

                        <a href="/">
                            <img src="../images/jolly-logo.png" class="st-logo-site" alt="Logo" />
                        </a>

                        <div class="sidebar-brand icon-ccv hidden-md">
                            <a href="#"></a>
                            <div id="close-sidebar">
                                <i class="fa fa-chevron-left"></i>
                            </div>
                        </div>
                    </div>




                    <div class="sidebar-menu">
                        <ul>

                            <li className={activeItem === 'Categories' ? 'active' : ''}>
                                <Link to="" onClick={() => handleItemClick('Categories')}>
                                    <FontAwesomeIcon icon={faList} className='st-icon-menu' />

                                    <span>Categories</span>
                                </Link>
                            </li>
                            <li className={activeItem === 'Cards' ? 'active' : ''}>
                                <Link to="Cards" onClick={() => handleItemClick('Cards')}>
                                    <FontAwesomeIcon icon={faList} className='st-icon-menu' />
                                    <span>Cards</span>
                                </Link>
                            </li>


                            <li className={activeItem === 'Users' ? 'active' : ''}>
                                <Link to='Users' onClick={() => handleItemClick('Users')}>
                                    <FontAwesomeIcon icon={faList} className='st-icon-menu' />

                                    <span>Users<span class="color"></span></span>
                                </Link>
                            </li>






                        </ul>
                    </div>
                    <div class=" sidebar-menu">
                        <ul>
                            <li>
                                <a onClick={logout}>
                                    <i className='fas fa-sign-out  fs-5 st-icon-menu'></i>
                                    <FontAwesomeIcon icon={faSignOut} className='st-icon-menu' />

                                    <span>Log out</span>
                                </a>
                            </li>
                            <li>
                                <a href="/">
                                    <span class="st-green-homepage">Home</span>
                                </a>
                            </li>
                        </ul>
                    </div>

                </div>
            </nav>

            <ToastContainer />
        </>
    );
};

export default Sidebarhome;
