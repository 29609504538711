import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOut, faClose } from '@fortawesome/free-solid-svg-icons';

const Sidebar = ({ categories, onCategorySelect, selectedCategory, setIsopen, isopen }) => {
  // Guard to ensure categories is an array before using map
  if (!Array.isArray(categories)) {
    console.error('Categories is not an array:', categories);
    return <div className=""></div>;
  }

  const isOpen = () => {
    setIsopen(!isopen);
  }


  function logout() {
    // Remove the token from localStorage (or cookies)
    localStorage.removeItem('token'); // Or document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 UTC;'
    localStorage.removeItem('role')
    // Optionally, redirect user after logout
    window.location.href = '/login'; // Redirect to login page
  }
  return (
    <div className={`sidebar ${isopen ? 'open' : ''}`}>
      <div className='img-icon'>
        <img src='/images/jolly-logo.png' alt="Logo" loading="lazy" />
        <FontAwesomeIcon icon={faClose} className='closeicon' onClick={isOpen} />
      </div>

      <ul>
        {categories.map((category, index) => (
          <li
            key={index}
            onClick={() => onCategorySelect(category.title)}
            className={category.title === selectedCategory ? 'selected' : ''}
          >
            <span className="icon">
              <img src={category.imageUrl} alt={category.title} loading="lazy" />
            </span>
            {category.title}
          </li>
        ))}

        <li onClick={logout}   className='logout'>
          <span className="icon">
            <FontAwesomeIcon icon={faSignOut} className='st-icon-menu' />
          </span>
          <span style={{marginLeft:"20px"}}>Log out</span>

        </li>
      </ul>
    </div>
  );
};

Sidebar.propTypes = {
  categories: PropTypes.array.isRequired,
  onCategorySelect: PropTypes.func.isRequired,
  selectedCategory: PropTypes.string.isRequired,
};

export default Sidebar;
