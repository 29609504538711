import React, { useState, useEffect } from 'react';
import Home from './components/Home';
import AdminDashboard from './Admin/AdminDashboard'
import LoginForm from './Admin/LoginForm'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import ProtectedRoute from './Admin/ProtectedRoute';
import UsersProtectRoute from './Admin/UsersProtectRoute';
import RegisterForm from './Admin/RegisterForm';
  



const App = () => {



  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={
          <UsersProtectRoute>
            <Home />
          </UsersProtectRoute>
        } />
 
        <Route path="/login" element={<LoginForm />} />
 
        {/* Admin Dashboard */}
        <Route
          path="/AdminDashboard/*"
          element={
            <ProtectedRoute>
              <AdminDashboard />
            </ProtectedRoute>
          }
        />
      </Routes>
    </BrowserRouter>

  );
};

export default App;
